import { FunctionComponent } from "react";
import {
  Column5, CustomLabel,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell, LocalMultiTextInput, LocalMultiTextInputMiddle, LocalMultiTextInputSmall,
  LocalPostCodeInput, LocalSelectExtend, LocalTextInput,
  LocalTextInputGrow, NowrapLabel,
  SectionHeadingLarge, TransValFieldGroup
} from "../commonStyle"
import { isEmpty } from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";
import {SupportPlanFormProps} from "../types";
import Text from "../../../../core/components/Text";
import {usePrefectures} from "../../../../core/hooks";
interface OrganizationSectionProps extends SupportPlanFormProps {
}

export const OrganizationSection: FunctionComponent<OrganizationSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
}) => {
  const { prefectureWithJaValOptions, findPrefEnValByJaVal } = usePrefectures();
  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>Ⅱ. 特定技能所属機関</SectionHeadingLarge>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 氏名又は名称 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>氏名又は名称</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalMultiTextInputSmall
                  placeholder="日本工業株式会社"
                  value={supportPlan?.organizationOfAffiliationJaName ?? ""}
                  invalid={isEmpty(supportPlan?.organizationOfAffiliationJaName)}
                  onTextChange={(val: string) => {
                    onChangeSupportPlan?.({
                      organizationOfAffiliationJaName: val,
                      supportSignatureJaAffiliationName: val
                    });
                  }}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalMultiTextInputSmall
                  placeholder="Nippon Kohgyo Co, Ltd."
                  value={supportPlan?.organizationOfAffiliationEnName ?? ""}
                  invalid={isEmpty(supportPlan?.organizationOfAffiliationEnName)}
                  onTextChange={(val: string) => {
                    onChangeSupportPlan?.({
                      organizationOfAffiliationEnName: val,
                      supportSignatureEnAffiliationName: val
                    });
                  }}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.organizationOfAffiliationJaName)
                  || isEmpty(supportPlan?.organizationOfAffiliationEnName))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 氏名又は名称ふりがな */}
        <GridRow>
          <LabelCell>
            <NowrapLabel required>氏名又は名称ふりがな</NowrapLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalMultiTextInput
                placeholder="にっぽんこうぎょうかぶしきかいしゃ"
                value={supportPlan?.organizationOfAffiliationNameFurigana ?? ""}
                invalid={isEmpty(supportPlan?.organizationOfAffiliationNameFurigana)}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({organizationOfAffiliationNameFurigana: val});
                }}
              />
              {isEmpty(supportPlan?.organizationOfAffiliationNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
        {/* 郵便番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>郵便番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalPostCodeInput
                value={supportPlan?.organizationOfAffiliationPostalCode ?? ""}
                onTextChange={(val) => {
                  onChangeSupportPlan?.({ organizationOfAffiliationPostalCode: val });
                }}
                onPrefectureChange={(val) => {
                  onChangeSupportPlan?.({
                    organizationOfAffiliationJaPrefecture: val.jaPrefecture,
                    organizationOfAffiliationEnPrefecture: val.enPrefecture
                  });
                }}
                onCityChange={(val) => {
                  onChangeSupportPlan?.({
                    organizationOfAffiliationJaCityWard: val.jaCity,
                    organizationOfAffiliationEnCityWard: val.enCity
                  });
                }}
                onTownChange={(val) => {
                  onChangeSupportPlan?.({
                    organizationOfAffiliationJaTown: val.jaTown,
                    organizationOfAffiliationEnTown: val.enTown
                  });
                }}
                error={isEmpty(supportPlan?.organizationOfAffiliationPostalCode)}
                suppressErrorMessage
                isDisplayNote
              />
              {isEmpty(supportPlan?.organizationOfAffiliationPostalCode) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 都道府県 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>都道府県</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalSelectExtend
                  placeholder="東京都"
                  options={prefectureWithJaValOptions}
                  value={supportPlan?.organizationOfAffiliationJaPrefecture}
                  onValueChange={(val) => {
                    onChangeSupportPlan?.({
                      organizationOfAffiliationJaPrefecture: val,
                      organizationOfAffiliationEnPrefecture: findPrefEnValByJaVal(val)
                    });
                  }}
                  error={isEmpty(supportPlan?.organizationOfAffiliationJaPrefecture)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  disabled
                  value={supportPlan?.organizationOfAffiliationEnPrefecture ?? ""}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.organizationOfAffiliationJaPrefecture)
                  || isEmpty(supportPlan?.organizationOfAffiliationEnPrefecture))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 市区 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>市区</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalTextInputGrow
                  placeholder="港区"
                  value={
                    supportPlan?.organizationOfAffiliationJaCityWard ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      organizationOfAffiliationJaCityWard: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.organizationOfAffiliationJaCityWard)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  placeholder="Minato-ku"
                  value={
                    supportPlan?.organizationOfAffiliationEnCityWard ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      organizationOfAffiliationEnCityWard: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.organizationOfAffiliationEnCityWard)}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.organizationOfAffiliationJaCityWard)
                  || isEmpty(supportPlan?.organizationOfAffiliationEnCityWard))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 町村 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>町村</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalTextInputGrow
                  placeholder="海岸"
                  value={
                    supportPlan?.organizationOfAffiliationJaTown ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      organizationOfAffiliationJaTown: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.organizationOfAffiliationJaTown)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  placeholder="Kaigan"
                  value={
                    supportPlan?.organizationOfAffiliationEnTown ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      organizationOfAffiliationEnTown: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.organizationOfAffiliationEnTown)}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.organizationOfAffiliationJaTown)
                  || isEmpty(supportPlan?.organizationOfAffiliationEnTown))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 番地 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>番地</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalTextInputGrow
                  placeholder="１丁目９−１８"
                  value={
                    supportPlan?.organizationOfAffiliationJaAddressNumber ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      organizationOfAffiliationJaAddressNumber: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.organizationOfAffiliationJaAddressNumber)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  placeholder="1-9-18"
                  value={
                    supportPlan?.organizationOfAffiliationEnAddressNumber ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      organizationOfAffiliationEnAddressNumber: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.organizationOfAffiliationEnAddressNumber)}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.organizationOfAffiliationJaAddressNumber)
                  || isEmpty(supportPlan?.organizationOfAffiliationEnAddressNumber))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 建物名等 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>建物名等</CustomLabel>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalMultiTextInputMiddle
                placeholder="國際浜松町ビル 2階"
                value={
                  supportPlan?.organizationOfAffiliationJaBuilding ?? ""
                }
                onTextChange={(val) => {
                  onChangeSupportPlan?.({
                    organizationOfAffiliationJaBuilding: val,
                  });
                }}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputMiddle
                placeholder="International Hamamatsucho Building 2F"
                value={
                  supportPlan?.organizationOfAffiliationEnBuilding ?? ""
                }
                onTextChange={(val) => {
                  onChangeSupportPlan?.({
                    organizationOfAffiliationEnBuilding: val,
                  });
                }}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 電話番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>電話番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInput
                placeholder="03-1234-5678"
                value={supportPlan?.organizationOfAffiliationPhoneNumber ?? ""}
                onTextChange={val => onChangeSupportPlan?.({ organizationOfAffiliationPhoneNumber: val })}
                error={isEmpty(supportPlan?.organizationOfAffiliationPhoneNumber)}
              />
              {isEmpty(supportPlan?.organizationOfAffiliationPhoneNumber) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default OrganizationSection;