import { FunctionComponent } from "react";
import styled from "styled-components";
import {
  Column5, CustomLabel,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell, LocalCheckBox, LocalSelect, LocalTextInputExtend,
  SectionHeadingLarge, WordBreakLabel,
} from "../commonStyle"
import { SelectOption } from "../../../../core/components/Select";
import {getLanguageCodeFromId, getLanguageIdFromCode, isEmpty} from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";
import {SupportPlanFormProps} from "../types";
import { SUPPORTED_LANGUAGE_OPTIONS } from "../../../../core/options/language"

interface SupportSignatureSectionProps extends SupportPlanFormProps {
  nationalityOptions?: SelectOption[];
}

const CheckBoxWrapper = styled.div`
  padding: 10px 30px;
`;

export const SupportSignatureSection: FunctionComponent<SupportSignatureSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
}) => {
  const updateOtherSectionLastName = (val: string | undefined) => {
    onChangeSupportPlan?.({
      guidanceWorkingConditionsEntrustingPersonLastName: val,
      guidanceActivityEntrustingPersonLastName: val,
      guidanceProceduresEntrustingPersonLastName: val,
      guidanceProhibitionsEntrustingPersonLastName: val,
      guidanceUnderstandingCostsEntrustingPersonLastName: val,
      guidanceNoBearingCostsEntrustingPersonLastName: val,
      guidancePickUpDropOffEntrustingPersonLastName: val,
      guidanceHousingEntrustingPersonLastName: val,
      guidanceRequestsEntrustingPersonLastName: val,
      guidancePersonContactEntrustingPersonLastName: val,
      pickUpDropOffArrivalEntrustingPersonLastName: val,
      pickUpDropOffDepartureEntrustingPersonLastName: val,
      lifeForeignerHousingEntrustingPersonLastName: val,
      lifeRentalHousingEntrustingPersonLastName: val,
      lifeOwnHousingEntrustingPersonLastName: val,
      lifeAccountOpeningEntrustingPersonLastName: val,
      lifeMobilePhoneEntrustingPersonLastName: val,
      lifeLifelineEntrustingPersonLastName: val,
      orientationGeneralLifeEntrustingPersonLastName: val,
      orientationProcedureEntrustingPersonLastName: val,
      orientationRequestsEntrustingPersonLastName: val,
      orientationMedicalEntrustingPersonLastName: val,
      orientationEmergencyEntrustingPersonLastName: val,
      orientationViolationEntrustingPersonLastName: val,
      opportunitiesAdmissionEntrustingPersonLastName: val,
      opportunitiesMaterialsEntrustingPersonLastName: val,
      opportunitiesTeachersEntrustingPersonLastName: val,
      requestsProperlyResponsesEntrustingPersonLastName: val,
      requestsAdministrativeOrgansEntrustingPersonLastName: val,
      interactionsProvidingEntrustingPersonLastName: val,
      interactionsCulturalEntrustingPersonLastName: val,
      jobChangeAcceptanceEntrustingPersonLastName: val,
      jobChangeIntroductionEntrustingPersonLastName: val,
      jobChangeRecommendationEntrustingPersonLastName: val,
      jobChangeMediationEntrustingPersonLastName: val,
      jobChangeProceduresEntrustingPersonLastName: val,
      jobChangeBankruptcyEntrustingPersonLastName: val,
      interviewsLaborSituationEntrustingPersonLastName: val,
      interviewsReconfirmationEntrustingPersonLastName: val,
      interviewsLegalViolationEntrustingPersonLastName: val,
      interviewsOtherProblemsEntrustingPersonLastName: val,
    });
  }

  const updateOtherSectionFirstName = (val: string | undefined) => {
    onChangeSupportPlan?.({
      guidanceWorkingConditionsEntrustingPersonFirstName: val,
      guidanceActivityEntrustingPersonFirstName: val,
      guidanceProceduresEntrustingPersonFirstName: val,
      guidanceProhibitionsEntrustingPersonFirstName: val,
      guidanceUnderstandingCostsEntrustingPersonFirstName: val,
      guidanceNoBearingCostsEntrustingPersonFirstName: val,
      guidancePickUpDropOffEntrustingPersonFirstName: val,
      guidanceHousingEntrustingPersonFirstName: val,
      guidanceRequestsEntrustingPersonFirstName: val,
      guidancePersonContactEntrustingPersonFirstName: val,
      pickUpDropOffArrivalEntrustingPersonFirstName: val,
      pickUpDropOffDepartureEntrustingPersonFirstName: val,
      lifeForeignerHousingEntrustingPersonFirstName: val,
      lifeRentalHousingEntrustingPersonFirstName: val,
      lifeOwnHousingEntrustingPersonFirstName: val,
      lifeAccountOpeningEntrustingPersonFirstName: val,
      lifeMobilePhoneEntrustingPersonFirstName: val,
      lifeLifelineEntrustingPersonFirstName: val,
      orientationGeneralLifeEntrustingPersonFirstName: val,
      orientationProcedureEntrustingPersonFirstName: val,
      orientationRequestsEntrustingPersonFirstName: val,
      orientationMedicalEntrustingPersonFirstName: val,
      orientationEmergencyEntrustingPersonFirstName: val,
      orientationViolationEntrustingPersonFirstName: val,
      opportunitiesAdmissionEntrustingPersonFirstName: val,
      opportunitiesMaterialsEntrustingPersonFirstName: val,
      opportunitiesTeachersEntrustingPersonFirstName: val,
      requestsProperlyResponsesEntrustingPersonFirstName: val,
      requestsAdministrativeOrgansEntrustingPersonFirstName: val,
      interactionsProvidingEntrustingPersonFirstName: val,
      interactionsCulturalEntrustingPersonFirstName: val,
      jobChangeAcceptanceEntrustingPersonFirstName: val,
      jobChangeIntroductionEntrustingPersonFirstName: val,
      jobChangeRecommendationEntrustingPersonFirstName: val,
      jobChangeMediationEntrustingPersonFirstName: val,
      jobChangeProceduresEntrustingPersonFirstName: val,
      jobChangeBankruptcyEntrustingPersonFirstName: val,
      interviewsLaborSituationEntrustingPersonFirstName: val,
      interviewsReconfirmationEntrustingPersonFirstName: val,
      interviewsLegalViolationEntrustingPersonFirstName: val,
      interviewsOtherProblemsEntrustingPersonFirstName: val,
    });
  }

  const updateOtherSectionLanguage = (val: number | undefined) => {
    onChangeSupportPlan?.({
      guidanceLanguageId: val,
      orientationLanguageId: val,
      requestsLanguageId: val,
      interviewsLanguageId: val,
    });
  }

  const setupSignatureInformation = (val: boolean) => {
    if (val) {
      updateOtherSectionLastName(supportPlan?.supportSignaturePersonFirstName);
      updateOtherSectionFirstName(supportPlan?.supportSignaturePersonLastName);
      updateOtherSectionLanguage(supportPlan?.supportSignatureLanguageId);
    }
    onChangeSupportPlan?.({ supportSignatureUseCommonInformation: val});
  }

  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>署名欄と共通情報</SectionHeadingLarge>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 特定技能所属機関の氏名又は名称 */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>特定技能所属機関の氏名又は名称</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                readOnly
                disabled
                value={supportPlan?.supportSignatureAffiliationName ?? ""}
              />
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 作成責任者の氏名（名字） */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>作成責任者の氏名（名字）</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="山田"
                value={supportPlan?.supportSignatureCreateManagerLastName ?? ""}
                error={isEmpty(supportPlan?.supportSignatureCreateManagerLastName)}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({supportSignatureCreateManagerLastName: val});
                }}
              />
              {isEmpty(supportPlan?.supportSignatureCreateManagerLastName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 作成責任者の氏名（名前） */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>作成責任者の氏名（名前）</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="太郎"
                value={supportPlan?.supportSignatureCreateManagerFirstName ?? ""}
                error={isEmpty(supportPlan?.supportSignatureCreateManagerFirstName)}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({supportSignatureCreateManagerFirstName: val});
                }}
              />
              {isEmpty(supportPlan?.supportSignatureCreateManagerFirstName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        <CheckBoxWrapper>
          <LocalCheckBox
            checked={!!supportPlan?.supportSignatureUseCommonInformation}
            onValueChange={(val) => setupSignatureInformation(val) }
          >
            「Ⅳ. 支援内容」の実施言語と担当者情報に、全て以下の情報を使用する
          </LocalCheckBox>
        </CheckBoxWrapper>

        {/* 説明言語 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>説明言語</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalSelect
                placeholder="選択"
                options={SUPPORTED_LANGUAGE_OPTIONS}
                value={getLanguageCodeFromId(supportPlan?.supportSignatureLanguageId) ?? ""}
                onValueChange={val => {
                  if (supportPlan?.supportSignatureUseCommonInformation)
                    updateOtherSectionLanguage(getLanguageIdFromCode(val));
                  onChangeSupportPlan?.({
                  supportSignatureLanguageId: getLanguageIdFromCode(val)
                  })
                }}
                error={isEmpty(supportPlan?.supportSignatureLanguageId)}
              />
              {isEmpty(supportPlan?.supportSignatureLanguageId) &&
                  <ErrorMessage>必ず選択してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 担当者の氏名（名字） */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>担当者の氏名（名字）</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="山田（雇用主組織の担当者）"
                value={supportPlan?.supportSignaturePersonLastName ?? ""}
                error={isEmpty(supportPlan?.supportSignaturePersonLastName)}
                onTextChange={(val: string) => {
                  if(supportPlan?.supportSignatureUseCommonInformation)
                    updateOtherSectionLastName(val);
                  onChangeSupportPlan?.({supportSignaturePersonLastName: val});
                }}
              />
              {isEmpty(supportPlan?.supportSignaturePersonLastName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 担当者の氏名（名前） */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>担当者の氏名（名前）</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="太郎（雇用主組織の担当者）"
                value={supportPlan?.supportSignaturePersonFirstName ?? ""}
                error={isEmpty(supportPlan?.supportSignaturePersonFirstName)}
                onTextChange={(val: string) => {
                  if(supportPlan?.supportSignatureUseCommonInformation)
                    updateOtherSectionFirstName(val);
                  onChangeSupportPlan?.({supportSignaturePersonFirstName: val});
                }}
              />
              {isEmpty(supportPlan?.supportSignaturePersonFirstName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

      </GridRowsContainer>
    </>
  );
};

export default SupportSignatureSection;