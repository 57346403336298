import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from './components/Select';
import { useReducerContext } from './contexts/ReducerContext';
import {isEmpty, isEmptyObject} from './utils';
import { Nationality } from './types/api';
import { LanguageCode } from './enums/language';
import { Job } from '../job/types/api';
import { PREFECTURES_EN, PREFECTURES_JA } from '../job/constants/prefectures';

type IndustryId = number | string;
type jobTypeId = number | string;

export const useComponentVisible = (initialVisible: boolean) => {
    const [visible, setVisible] = useState(initialVisible)
    const ref = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
            setVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    return {ref, visible, setVisible}
}

export const useJobPostCategory = () => {
    const { state } = useReducerContext();
    const { i18n } = useTranslation();
    const { industries, jobTypes } = state; 
    const getLangKey = (langCode?: string): string => `${langCode || i18n.language}Name`;
 
    const getIndustryName = (industryId?: IndustryId | null, langCode?: string): string => {
            if (!industries || isEmptyObject(industries) || isNaN(Number(industryId)))
                return '';

            const langKey = getLangKey(langCode);
            return String(industries[Number(industryId)]?.[langKey] || ''); 
        }

    const getJobTypeName = (jobTypeId?: jobTypeId | null, langCode?: string): string => {
            if (!jobTypes || isEmptyObject(jobTypes) || isNaN(Number(jobTypeId)))
                return '';

            const langKey = getLangKey(langCode);
            return String(jobTypes[Number(jobTypeId)]?.[langKey] || '');
        }

    const getIndustryIdByJobTypeId = (jobTypeId?: jobTypeId | null): string => {
            if (!jobTypes || isEmptyObject(jobTypes) || isNaN(Number(jobTypeId)))
                return '';

            const industryId = jobTypes[Number(jobTypeId)]?.industryId;
            return industryId ? String(industryId) : '';
        }

    const getIndustryNameByJobTypeId = (jobTypeId?: jobTypeId | null, langCode?: string): string => {
            if (!industries ||
                !jobTypes ||
                isEmptyObject(industries) ||
                isEmptyObject(jobTypes) || 
                isNaN(Number(jobTypeId))
            )
                return '';

            const langKey = getLangKey(langCode);
            const industryId = jobTypes[Number(jobTypeId)]?.industryId;

            return industryId ? String(industries[industryId]?.[langKey]) : '';
        }

    return {
        getIndustryName,
        getJobTypeName,
        getIndustryIdByJobTypeId,
        getIndustryNameByJobTypeId
    }    
}

export const useJobPostOptions = () => {
    const { i18n } = useTranslation();
    const { state } = useReducerContext();
    const { industries } = state;
    
    const industryOptions = useMemo((): SelectOption [] => {
        if (!industries || isEmptyObject(industries))
            return [];

        const options: SelectOption[] = [];
        for (const [industryId, industry] of Object.entries(industries)) {
            options.push({
                label: String(industry[`${i18n.language}Name`] ?? ""),
                value: industryId,
            });    
        }

        return options;
    }, [industries, i18n.language]);

    const jobTypeOptionsDic = useMemo((): Record<IndustryId, SelectOption[]> => {
        if (!industries || isEmptyObject(industries))
            return {};

        const jobTypeOptionsDic: Record<IndustryId, SelectOption[]> = {};
        for (const [industryId, industry] of Object.entries(industries)) {
            jobTypeOptionsDic[industryId] = industry.jobTypes.map(jobType => ({
                label: String(jobType[`${i18n.language}Name`] ?? ""),
                value: String(jobType.id),
            }));
        }

        return jobTypeOptionsDic;
    }, [industries, i18n.language]);

    const prefectureWithJaValOptions = PREFECTURES_EN.map((pref, i) => ({
        label: `prefecture.${pref.toLowerCase().split(' ')[0]}`,
        value: PREFECTURES_JA[i],
    }));

    const findPrefEnValByJaVal = (jaVal: string): string => {
        const index = PREFECTURES_JA.indexOf(jaVal);
        return PREFECTURES_EN[index];
    }

    return {
        industryOptions,
        jobTypeOptionsDic,
        prefectureWithJaValOptions,
        findPrefEnValByJaVal
    }
}

//Note: as the number of support langauges increases,
//it probably will be better to handle the concatenation of the value and the unit in the language json file
//rather than handling here because the value/unit order might be different.
export const useValueUnit = () => {
    const { t } = useTranslation();
    const addDay = (from?: string | number | null, to?: string | number | null): string => {
        if (isEmpty(to)) return isEmpty(from) ? "" : `${from} ${t('core.units.days')}`;
        return isEmpty(from) ? `〜 ${to} ${t('core.units.days')}` : `${from} 〜 ${to} ${t('core.units.days')}`;
    }
    const addHourAndMinute = (hour?: string | number | null, minute?: string | number | null): string => {
        const hourStr = hour == null ? "" : `${hour} ${t('core.units.hours')}`;
        const minuteStr = minute == null ? "" : `${minute} ${t('core.units.minutes')}`;
        return `${hourStr} ${minuteStr}`.trim();
    }
    const addHour = (value?: string | number | null): string => {
        return value == null ? "" : `${value} ${t('core.units.hours')}`;
    }
    const addMinute = (value?: string | number | null): string => {
        return value == null ? "" : `${value} ${t('core.units.minutes')}`;
    }
    const addYen = (value?: string | number | null): string => {
        return value == null ? "" : `${value} ${t('core.units.yen')}`;
    }
    const addYearsOld = (value?: string | number | null): string => {
        return value == null ? "" : `${value} ${t('core.units.years_old')}`;
    }   
    const addTimes = (value?: string | number | null): string => {
        return value == null ? "" : `${value} ${t('core.units.times')}`;
    }
    const formatAndAddYen = (value?: string | number | null): string => {
        return value == null ? "" : `${Number(value).toLocaleString()} ${t('core.units.yen')}`;
    }

    return {
        addDay,
        addHourAndMinute,
        addHour,
        addMinute,
        addYen,
        addYearsOld,
        addTimes,
        formatAndAddYen
    }
}

export const useNationalities = () => {
    const { i18n, t } = useTranslation();
    const { state } = useReducerContext()
    const { nationalities } = state


    const nationalityList = useMemo(
        () => Object.values(nationalities).map(nationality => nationality)
        , [nationalities]
    )

    const nationalityOptions: SelectOption[] = useMemo(() => {
        return Object.values(state.nationalities).map((nationality) => ({
          label: nationality.name,
          value: String(nationality.id),
        }));
      }, [nationalities]);

    const getTranslatedNationalityList = (nationalities: Nationality[]): Nationality[] => {
        return nationalities.map(nationality => (
            {
                ...nationality,
                name: i18n.exists(`core.nationalities.${nationality.tkey}`)
                    ? t(`core.nationalities.${nationality.tkey}`)
                    : nationality.name,
            } as Nationality
        ))
    }

    const getSupportedLangCodeByNationality = (nationality: Nationality | null): string => {
        if (!nationality)
            return "";

        /* Japanese shouldn't included here because candidates are normally foreigners. */
        switch (nationality.tkey) {
            case "vietnam":
                return LanguageCode.Vietnamese;
            case "myanmar_burma":
                return LanguageCode.Myanmar;
            case "indonesia":
                return LanguageCode.Indonesian;
            case "nepal":
                return LanguageCode.Nepali;
            default:
                return "";
        }
    }

    const getSupportedLangCodeByNationalityId = (nationalityId: number | null): string => {
        if (!nationalityId)
            return "";

        const nationality = nationalities[nationalityId];
        return getSupportedLangCodeByNationality(nationality);
    }

    const getNationalityName = (nationalityId: number | null): string => {
        if (!nationalityId)
            return "";

        return nationalities[nationalityId]?.name || "";
    }

    return {
        nationalityList,
        nationalityOptions,
        getTranslatedNationalityList,
        getSupportedLangCodeByNationality,
        getSupportedLangCodeByNationalityId,
        getNationalityName
    }
}

export const useJobLocation = () => {
    const { i18n } = useTranslation();

    const getJobLocation = (job: Partial<Job>, options?: {
        onlyPrefecture?: boolean,
        delimeter?: string,
    }): string => {
        const delimeter = options?.delimeter ?? ' ';
        const onlyPrefecture = options?.onlyPrefecture;
        const jaAddress = [
            job.ecPlaceOfEmploymentJaPrefecture,
            onlyPrefecture ? '' : job.ecPlaceOfEmploymentJaCityWard,
            onlyPrefecture ? '' : job.ecPlaceOfEmploymentJaTown,
        ].join(delimeter).trim();
    
        const enAddress = [
            job.ecPlaceOfEmploymentEnPrefecture,
            onlyPrefecture ? '' : job.ecPlaceOfEmploymentEnCityWard,
            onlyPrefecture ? '' : job.ecPlaceOfEmploymentEnTown,
        ].join(delimeter).trim();
    
        return i18n.language === LanguageCode.Japanese ? jaAddress : enAddress;
    }

    return {
        getJobLocation
    }
}