import { FunctionComponent } from "react";
import styled from "styled-components";
import {
  Column5, CustomLabel, DatePickerWrapper,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell, LocalMultiTextInput, LocalMultiTextInputMiddle, LocalMultiTextInputSmall,
  LocalPostCodeInput, LocalSelectExtend, LocalTextInput, LocalTextInputExtend,
  LocalTextInputGrow, LocalTextInputSmall, NowrapLabel,
  SectionHeadingLarge, TransValFieldGroup, WordBreakLabel
} from "../commonStyle"
import Text from "../../../../core/components/Text";
import { isEmpty } from "../../../../core/utils";
import ErrorMessage from "../components/ErrorMessage";
import {SupportPlanFormProps} from "../types";
import DatePicker from "../../../../core/components/DatePicker";
import {usePrefectures} from "../../../../core/hooks";
interface SupportOrganizationBaseSectionProps extends SupportPlanFormProps {
}

const LocalRow = styled.div`
  display: flex;
  gap: 10px;
`;

export const SupportOrganizationBaseSection: FunctionComponent<SupportOrganizationBaseSectionProps> = (
  {
    supportPlan,
    onChangeSupportPlan,
}) => {
  const { prefectureWithJaValOptions, findPrefEnValByJaVal } = usePrefectures();
  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>Ⅲ. 登録支援機関（基本情報）</SectionHeadingLarge>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 登録番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>登録番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalRow>
                <LocalTextInputSmall
                  placeholder="23"
                  value={supportPlan?.supportOrganizationBaseRegistrationNumberPrefix ?? ""}
                  onTextChange={val => onChangeSupportPlan?.({ supportOrganizationBaseRegistrationNumberPrefix: val })}
                  error={isEmpty(supportPlan?.supportOrganizationBaseRegistrationNumberPrefix)}
                />
                <Text>登</Text>
                <LocalTextInput
                  placeholder="008552"
                  value={supportPlan?.supportOrganizationBaseRegistrationNumberSuffix ?? ""}
                  onTextChange={val => onChangeSupportPlan?.({ supportOrganizationBaseRegistrationNumberSuffix: val })}
                  error={isEmpty(supportPlan?.supportOrganizationBaseRegistrationNumberSuffix)}
                />
              </LocalRow>
              {(isEmpty(supportPlan?.supportOrganizationBaseRegistrationNumberPrefix) || isEmpty(supportPlan?.supportOrganizationBaseRegistrationNumberSuffix))
                && <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 登録年月日 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>登録年月日</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <DatePickerWrapper>
                <DatePicker
                  placeholder="2000/01/23"
                  selected={
                    supportPlan?.supportOrganizationBaseRegistrationDate
                      ? new Date(supportPlan?.supportOrganizationBaseRegistrationDate)
                      : undefined
                  }
                  onChange={(val) =>
                    onChangeSupportPlan?.({supportOrganizationBaseRegistrationDate: val.toISOString()})
                  }
                  customInput={<LocalTextInput/>}
                  error={isEmpty(supportPlan?.supportOrganizationBaseRegistrationDate)}
                />
              </DatePickerWrapper>
              {isEmpty(supportPlan?.supportOrganizationBaseRegistrationDate) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 支援業務を開始する予定年月日 */}
        <GridRow>
          <LabelCell>
            <WordBreakLabel required>支援業務を開始する予定年月日</WordBreakLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <DatePickerWrapper>
                <DatePicker
                  placeholder="2000/01/23"
                  selected={
                    supportPlan?.supportOrganizationBaseScheduledStartDate
                      ? new Date(supportPlan?.supportOrganizationBaseScheduledStartDate)
                      : undefined
                  }
                  onChange={(val) =>
                    onChangeSupportPlan?.({supportOrganizationBaseScheduledStartDate: val.toISOString()})
                  }
                  customInput={<LocalTextInput/>}
                  error={isEmpty(supportPlan?.supportOrganizationBaseScheduledStartDate)}
                />
              </DatePickerWrapper>
              {isEmpty(supportPlan?.supportOrganizationBaseScheduledStartDate) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 氏名又は名称 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>氏名又は名称</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalMultiTextInputSmall
                  placeholder="日本工業株式会社"
                  value={supportPlan?.supportOrganizationBaseJaName ?? ""}
                  invalid={isEmpty(supportPlan?.supportOrganizationBaseJaName)}
                  onTextChange={(val: string) => {
                    onChangeSupportPlan?.({
                      supportOrganizationBaseJaName: val
                    });
                  }}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalMultiTextInputSmall
                  placeholder="Nippon Kohgyo Co, Ltd."
                  value={supportPlan?.supportOrganizationBaseEnName ?? ""}
                  invalid={isEmpty(supportPlan?.supportOrganizationBaseEnName)}
                  onTextChange={(val: string) => {
                    onChangeSupportPlan?.({
                      supportOrganizationBaseEnName: val
                    });
                  }}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.supportOrganizationBaseJaName)
                  || isEmpty(supportPlan?.supportOrganizationBaseEnName))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 氏名又は名称ふりがな */}
        <GridRow>
          <LabelCell>
            <NowrapLabel required>氏名又は名称ふりがな</NowrapLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalMultiTextInput
                placeholder="にっぽんこうぎょうかぶしきかいしゃ"
                value={supportPlan?.supportOrganizationBaseNameFurigana ?? ""}
                invalid={isEmpty(supportPlan?.supportOrganizationBaseNameFurigana)}
                onTextChange={(val: string) => {
                  onChangeSupportPlan?.({supportOrganizationBaseNameFurigana: val});
                }}
              />
              {isEmpty(supportPlan?.supportOrganizationBaseNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 郵便番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>郵便番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalPostCodeInput
                value={supportPlan?.supportOrganizationBasePostalCode ?? ""}
                onTextChange={(val) => {
                  onChangeSupportPlan?.({ supportOrganizationBasePostalCode: val });
                }}
                onPrefectureChange={(val) => {
                  onChangeSupportPlan?.({
                    supportOrganizationBaseJaPrefecture: val.jaPrefecture,
                    supportOrganizationBaseEnPrefecture: val.enPrefecture
                  });
                }}
                onCityChange={(val) => {
                  onChangeSupportPlan?.({
                    supportOrganizationBaseJaCityWard: val.jaCity,
                    supportOrganizationBaseEnCityWard: val.enCity
                  });
                }}
                onTownChange={(val) => {
                  onChangeSupportPlan?.({
                    supportOrganizationBaseJaTown: val.jaTown,
                    supportOrganizationBaseEnTown: val.enTown
                  });
                }}
                error={isEmpty(supportPlan?.supportOrganizationBasePostalCode)}
                suppressErrorMessage
                isDisplayNote
              />
              {isEmpty(supportPlan?.supportOrganizationBasePostalCode) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 都道府県 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>都道府県</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalSelectExtend
                  placeholder="東京都"
                  options={prefectureWithJaValOptions}
                  value={supportPlan?.supportOrganizationBaseJaPrefecture}
                  onValueChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationBaseJaPrefecture: val,
                      supportOrganizationBaseEnPrefecture: findPrefEnValByJaVal(val)
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationBaseJaPrefecture)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  disabled
                  value={supportPlan?.supportOrganizationBaseEnPrefecture ?? ""}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.supportOrganizationBaseJaPrefecture)
                  || isEmpty(supportPlan?.supportOrganizationBaseEnPrefecture))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 市区 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>市区</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalTextInputGrow
                  placeholder="港区"
                  value={
                    supportPlan?.supportOrganizationBaseJaCityWard ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationBaseJaCityWard: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationBaseJaCityWard)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  placeholder="Minato-ku"
                  value={
                    supportPlan?.supportOrganizationBaseEnCityWard ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationBaseEnCityWard: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationBaseEnCityWard)}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.supportOrganizationBaseJaCityWard)
                  || isEmpty(supportPlan?.supportOrganizationBaseEnCityWard))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 町村 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>町村</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalTextInputGrow
                  placeholder="海岸"
                  value={
                    supportPlan?.supportOrganizationBaseJaTown ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationBaseJaTown: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationBaseJaTown)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  placeholder="Kaigan"
                  value={
                    supportPlan?.supportOrganizationBaseEnTown ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationBaseEnTown: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationBaseEnTown)}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.supportOrganizationBaseJaTown)
                  || isEmpty(supportPlan?.supportOrganizationBaseEnTown))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 番地 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>番地</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <TransValFieldGroup>
                <Text>日</Text>
                <LocalTextInputGrow
                  placeholder="１丁目９−１８"
                  value={
                    supportPlan?.supportOrganizationBaseJaAddressNumber ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationBaseJaAddressNumber: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationBaseJaAddressNumber)}
                />
                <Text>/</Text>
                <Text>EN</Text>
                <LocalTextInputGrow
                  placeholder="1-9-18"
                  value={
                    supportPlan?.supportOrganizationBaseEnAddressNumber ?? ""
                  }
                  onTextChange={(val) => {
                    onChangeSupportPlan?.({
                      supportOrganizationBaseEnAddressNumber: val,
                    });
                  }}
                  error={isEmpty(supportPlan?.supportOrganizationBaseEnAddressNumber)}
                />
              </TransValFieldGroup>
              {(isEmpty(supportPlan?.supportOrganizationBaseJaAddressNumber)
                  || isEmpty(supportPlan?.supportOrganizationBaseEnAddressNumber))
                &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 建物名等 */}
        <GridRow>
          <LabelCell>
            <CustomLabel>建物名等</CustomLabel>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalMultiTextInputMiddle
                placeholder="國際浜松町ビル 2階"
                value={
                  supportPlan?.supportOrganizationBaseJaBuilding ?? ""
                }
                onTextChange={(val) => {
                  onChangeSupportPlan?.({
                    supportOrganizationBaseJaBuilding: val,
                  });
                }}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputMiddle
                placeholder="International Hamamatsucho Building 2F"
                value={
                  supportPlan?.supportOrganizationBaseEnBuilding ?? ""
                }
                onTextChange={(val) => {
                  onChangeSupportPlan?.({
                    supportOrganizationBaseEnBuilding: val,
                  });
                }}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 電話番号 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>電話番号</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInput
                placeholder="03-1234-5678"
                value={supportPlan?.supportOrganizationBasePhoneNumber ?? ""}
                onTextChange={val => onChangeSupportPlan?.({ supportOrganizationBasePhoneNumber: val })}
                error={isEmpty(supportPlan?.supportOrganizationBasePhoneNumber)}
              />
              {isEmpty(supportPlan?.supportOrganizationBasePhoneNumber) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
        {/* 代表者の名字 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>代表者の名字</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="山田"
                value={supportPlan?.supportOrganizationBaseRepresentativeLastName ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationBaseRepresentativeLastName: val })}
                error={isEmpty(supportPlan?.supportOrganizationBaseRepresentativeLastName)}
              >
              </LocalTextInputExtend>
              {isEmpty(supportPlan?.supportOrganizationBaseRepresentativeLastName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
        {/* 代表者の名前 */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>代表者の名前</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="太郎"
                value={supportPlan?.supportOrganizationBaseRepresentativeFirstName ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationBaseRepresentativeFirstName: val })}
                error={isEmpty(supportPlan?.supportOrganizationBaseRepresentativeFirstName)}
              >
              </LocalTextInputExtend>
              {isEmpty(supportPlan?.supportOrganizationBaseRepresentativeFirstName) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
        {/* 代表者の名字ふりがな */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>代表者の名字ふりがな</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="やまだ"
                value={supportPlan?.supportOrganizationBaseRepresentativeLastNameFurigana ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationBaseRepresentativeLastNameFurigana: val })}
                error={isEmpty(supportPlan?.supportOrganizationBaseRepresentativeLastNameFurigana)}
              >
              </LocalTextInputExtend>
              {isEmpty(supportPlan?.supportOrganizationBaseRepresentativeLastNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
        {/* 代表者の名前ふりがな */}
        <GridRow>
          <LabelCell>
            <CustomLabel required>代表者の名前ふりがな</CustomLabel>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalTextInputExtend
                placeholder="たろう"
                value={supportPlan?.supportOrganizationBaseRepresentativeFirstNameFurigana ?? ""}
                onTextChange={val =>
                  onChangeSupportPlan?.({ supportOrganizationBaseRepresentativeFirstNameFurigana: val })}
                error={isEmpty(supportPlan?.supportOrganizationBaseRepresentativeFirstNameFurigana)}
              >
              </LocalTextInputExtend>
              {isEmpty(supportPlan?.supportOrganizationBaseRepresentativeFirstNameFurigana) &&
                  <ErrorMessage>必ず入力してください</ErrorMessage>
              }
            </Column5>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default SupportOrganizationBaseSection;