import { FunctionComponent } from "react";
import {
  Column5,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell,
  LocalMultiTextInputMiddle,
  LocalMultiTextInputSmall,
  LocalPostalCodeInput,
  LocalSelectExtend,
  LocalTextInput,
  LocalTextInputGrow,
  LocalTextInputSmall,
  PostalCodeAndNoteWrapper,
  RadioButtonsWrapper,
  Row5,
  SectionHeadingLarge,
  TransValFieldGroup,
  TransValFieldGroupStartAligned,
  WarningNote,
} from "../commonStyle";
import Label from "../../../../core/components/Label";
import Text from "../../../../core/components/Text";
import { JobFormSectionProps } from "../type";
import { useTranslation } from "react-i18next";
import DateField from "../../../../core/components/DateField";
import Radio from "../../../../core/components/Radio";
import TimeField from "../../../../core/components/TimeField";
import { isEmpty } from "../../../../core/utils";
import { usePrefectures } from "../../../../core/hooks";

interface InfoAboutEmploymentSectionProps extends JobFormSectionProps {}


const InfoAboutEmploymentSection: FunctionComponent<
  InfoAboutEmploymentSectionProps
> = ({ job, onChange }) => {
  const { t: tCore } = useTranslation("translation", { keyPrefix: "core" });
  const { prefectureWithJaValOptions, findPrefEnValByJaVal } = usePrefectures();
  
  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>就業に関する情報</SectionHeadingLarge>
        <WarningNote>
          ※候補者/パートナーには「都道府県」「市区」「町村」のみ表示され、郵便番号や番地以降は表示されません。
        </WarningNote>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 事務所名 */}
        <GridRow>
          <LabelCell>
            <Label>事務所名</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroupStartAligned>
              <Text>日</Text>
              <LocalMultiTextInputSmall 
                placeholder="日本工業株式会社 東京支社" 
                value={job?.ecPlaceOfEmploymentJaOfficeName ?? ""}
                onTextChange={(val) => onChange?.({ ecPlaceOfEmploymentJaOfficeName: val })}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputSmall 
                placeholder="Nippon Kohgyo Co, Ltd. Tokyo Branch" 
                value={job?.ecPlaceOfEmploymentEnOfficeName ?? ""}
                onTextChange={(val) => onChange?.({ ecPlaceOfEmploymentEnOfficeName: val })}
              />
            </TransValFieldGroupStartAligned>
          </FieldCell>
        </GridRow>

        {/* 郵便番号 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("postal_code")}</Label>
          </LabelCell>
          <FieldCell>
            <PostalCodeAndNoteWrapper>
              <Column5>
                <LocalPostalCodeInput
                  suppressErrorMessage
                  suppressAutoFillMessage
                  clearAddrInfoWhenIncomplete
                  value={job?.ecPlaceOfEmploymentPostalCode ?? ""}
                  onTextChange={(val) => {
                    onChange?.({ 
                      ecPlaceOfEmploymentPostalCode: val
                    });
                  }}
                  onPrefectureChange={(val) => {
                    onChange?.({
                      ecPlaceOfEmploymentJaPrefecture: val.jaPrefecture,
                      ecPlaceOfEmploymentEnPrefecture: val.enPrefecture
                    });
                  }}
                  onCityChange={(val) => {
                    onChange?.({
                      ecPlaceOfEmploymentJaCityWard: val.jaCity,
                      ecPlaceOfEmploymentEnCityWard: val.enCity
                    });
                  }}
                  onTownChange={(val) => {
                    onChange?.({
                      ecPlaceOfEmploymentJaTown: val.jaTown,
                      ecPlaceOfEmploymentEnTown: val.enTown
                    });
                  }}

                  error={isEmpty(job?.ecPlaceOfEmploymentPostalCode)}
                />
              </Column5>
              <WarningNote>
                ※郵便番号を入力すると、該当する都道府県、市区、町村が自動入力されます。
              </WarningNote>
            </PostalCodeAndNoteWrapper>
          </FieldCell>
        </GridRow>

        {/* 都道府県 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("prefecture")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalSelectExtend
                placeholder={tCore("select")}
                options={prefectureWithJaValOptions}
                value={job?.ecPlaceOfEmploymentJaPrefecture}
                onValueChange={(val) => {
                  onChange?.({
                    ecPlaceOfEmploymentJaPrefecture: val,
                    ecPlaceOfEmploymentEnPrefecture: findPrefEnValByJaVal(val)
                  });
                }}
                error={isEmpty(job?.ecPlaceOfEmploymentJaPrefecture)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                disabled
                value={job?.ecPlaceOfEmploymentEnPrefecture ?? ""}
                onTextChange={val => onChange?.({ 
                  ecPlaceOfEmploymentEnPrefecture: val
                })}
                error={isEmpty(job?.ecPlaceOfEmploymentEnPrefecture)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 市区 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("city")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="港区"
                value={job?.ecPlaceOfEmploymentJaCityWard ?? ""}
                onTextChange={val => onChange?.({ 
                  ecPlaceOfEmploymentJaCityWard: val
                })}
                error={isEmpty(job?.ecPlaceOfEmploymentJaCityWard)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Minato-ku"
                value={job?.ecPlaceOfEmploymentEnCityWard ?? ""}
                onTextChange={val => onChange?.({ 
                  ecPlaceOfEmploymentEnCityWard: val
                })}
                error={isEmpty(job?.ecPlaceOfEmploymentEnCityWard)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 町村 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("town_name")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="海岸"
                value={job?.ecPlaceOfEmploymentJaTown ?? ""}
                onTextChange={val => onChange?.({ 
                  ecPlaceOfEmploymentJaTown: val
                })}
                error={isEmpty(job?.ecPlaceOfEmploymentJaTown)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Kaigan"
                value={job?.ecPlaceOfEmploymentEnTown ?? ""}
                onTextChange={val => onChange?.({ 
                  ecPlaceOfEmploymentEnTown: val
                })}
                error={isEmpty(job?.ecPlaceOfEmploymentEnTown)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 番地 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("locality")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="１丁目９−１８"
                value={job?.jaAddressNumber ?? ""}
                onTextChange={(val) => onChange?.({
                  jaAddressNumber: val,
                  ecPlaceOfEmploymentJaAddressNumber: val
                })}
                error={isEmpty(job?.jaAddressNumber)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="1-9-18"
                value={job?.enAddressNumber ?? ""}
                onTextChange={(val) => onChange?.({
                  enAddressNumber: val,
                  ecPlaceOfEmploymentEnAddressNumber: val
                })}
                error={isEmpty(job?.enAddressNumber)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 建物名等 */}
        <GridRow>
          <LabelCell>
            <Label>{tCore("building_etc")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroupStartAligned>
              <Text>日</Text>
              <LocalMultiTextInputMiddle
                placeholder="國際浜松町ビル 2階"
                value={job?.jaBuildingName ?? ""}
                onTextChange={(val) => onChange?.({
                  jaBuildingName: val,
                  ecPlaceOfEmploymentJaBuilding: val
                })}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputMiddle
                placeholder="International Hamamatsucho Building 2F"
                value={job?.enBuildingName ?? ""}
                onTextChange={(val) => onChange?.({
                  enBuildingName: val,
                  ecPlaceOfEmploymentEnBuilding: val
                })}
              />
            </TransValFieldGroupStartAligned>
          </FieldCell>
        </GridRow>

        {/* 電話番号 */}
        <GridRow>
          <LabelCell>
            <Label>{tCore("phone_number")}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInput
              placeholder="03-1234-5678"
              value={job?.ecPlaceOfEmploymentPhoneNumber ?? ""}
              onTextChange={(val) =>
                onChange?.({ ecPlaceOfEmploymentPhoneNumber: val })
              }
            />
          </FieldCell>
        </GridRow>

        {/* 雇用管理の改善等に関する相談窓口（部署名）*/}
        <GridRow>
          <LabelCell>
            <Label>雇用管理の改善等に関する相談窓口（部署名）</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroupStartAligned>
              <Text>日</Text>
              <LocalMultiTextInputMiddle
                value={job?.ecOtherDepartmentConsultationEmploymentManagementJa ?? ""}
                onTextChange={(val) => onChange?.({
                  ecOtherDepartmentConsultationEmploymentManagementJa: val
                })}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputMiddle
                value={job?.ecOtherDepartmentConsultationEmploymentManagementEn ?? ""}
                onTextChange={(val) => onChange?.({
                  ecOtherDepartmentConsultationEmploymentManagementEn: val
                })}
              />
            </TransValFieldGroupStartAligned>
          </FieldCell>
        </GridRow>

        {/* 雇用管理の改善等に関する相談窓口（担当者職氏名）*/}
        <GridRow>
          <LabelCell>
            <Label>雇用管理の改善等に関する相談窓口（担当者職氏名）</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroupStartAligned>
              <Text>日</Text>
              <LocalMultiTextInputMiddle
                value={job?.ecOtherManagerConsultationEmploymentManagementJa ?? ""}
                onTextChange={(val) => onChange?.({
                  ecOtherManagerConsultationEmploymentManagementJa: val
                })}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputMiddle
                value={job?.ecOtherManagerConsultationEmploymentManagementEn ?? ""}
                onTextChange={(val) => onChange?.({
                  ecOtherManagerConsultationEmploymentManagementEn: val
                })}
              />
            </TransValFieldGroupStartAligned>
          </FieldCell>
        </GridRow>

        {/* 雇用管理の改善等に関する相談窓口（連絡先電話番号） */}
        <GridRow>
          <LabelCell>
            <Label>雇用管理の改善等に関する相談窓口（連絡先電話番号）</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInput
              placeholder="03-1234-5678"
              value={job?.ecOtherContactConsultationEmploymentManagement ?? ""}
              onTextChange={(val) => onChange?.({
                ecOtherContactConsultationEmploymentManagement: val
              })}
            />
          </FieldCell>
        </GridRow>

        {/* 始業時刻 */}
        <GridRow>
          <LabelCell>
            <Label>始業時刻</Label>
          </LabelCell>
          <FieldCell>
            <TimeField 
              hours={job?.ecWorkHoursStartTimeHour}
              minutes={job?.ecWorkHoursStartTimeMinute}
              onHoursChange={(val) => onChange?.({ ecWorkHoursStartTimeHour: val })}
              onMinutesChange={(val) => onChange?.({ ecWorkHoursStartTimeMinute: val })}
            />
          </FieldCell>
        </GridRow>

        {/* 終業時刻 */}
        <GridRow>
          <LabelCell>
            <Label>終業時刻</Label>
          </LabelCell>
          <FieldCell>
            <TimeField 
              hours={job?.ecWorkHoursEndTimeHour}
              minutes={job?.ecWorkHoursEndTimeMinute}
              onHoursChange={(val) => onChange?.({ ecWorkHoursEndTimeHour: val })}
              onMinutesChange={(val) => onChange?.({ ecWorkHoursEndTimeMinute: val })}
            />
          </FieldCell>
        </GridRow>

        {/* 賞与 */}
        <GridRow>
          <LabelCell>
            <Label>賞与</Label>
          </LabelCell>
          <FieldCell>
            <RadioButtonsWrapper>
              <Radio
                checked={job?.bonus === true}
                onChange={() => onChange?.({ bonus: true })}
              >
                あり
              </Radio>
              <Radio
                checked={job?.bonus === false}
                onChange={() => onChange?.({ bonus: false })}
                >なし
              </Radio>
            </RadioButtonsWrapper>
          </FieldCell>
        </GridRow>

        {/* 雇入れ時の健康診断 */}
        <GridRow>
          <LabelCell>
            <Label>雇入れ時の健康診断</Label>
          </LabelCell>
          <FieldCell>
            <DateField 
              onlyYearAndMonth 
              value={job?.ecOtherMedicalExaminationAtTheTimeOfHiring ?? ""}
              onDateChange={(val) => onChange?.({
                ecOtherMedicalExaminationAtTheTimeOfHiring: val
              })}
            />
          </FieldCell>
        </GridRow>

        {/* 初回の定期健康診断 */}
        <GridRow>
          <LabelCell>
            <Label>初回の定期健康診断</Label>
          </LabelCell>
          <FieldCell>
            <DateField 
              onlyYearAndMonth 
              value={job?.ecOtherFirstPeriodicMedicalExamination ?? ""}
              onDateChange={(val) => onChange?.({
                ecOtherFirstPeriodicMedicalExamination: val
              })}
            />
          </FieldCell>
        </GridRow>

        {/* 2回目以降の定期健康診断 */}
        <GridRow>
          <LabelCell>
            <Label>2回目以降の定期健康診断</Label>
          </LabelCell>
          <FieldCell>
            <Row5>
              <LocalTextInputSmall
                placeholder="1年"
                value={job?.ecOtherSecondAndSubsequentPeriodicMedicalExaminations ?? ""}
                onTextChange={(val) => onChange?.({
                  ecOtherSecondAndSubsequentPeriodicMedicalExaminations: val
                })}
                style={{ textAlign: "right" }}
              />
              <Text>ごとに実施</Text>
            </Row5>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default InfoAboutEmploymentSection;
